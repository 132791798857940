@import 'leaflet/dist/leaflet.css';

body {
  background-image: url('../../public/img/bg-white.avif');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body.dark-mode {
  background-image: url('../../public/img/bg-black.avif');
}

a,
a:visited,
a:hover,
a:active {
  color: inherit !important;
  text-decoration: none !important;
}

.underline-force {
  text-decoration: underline !important;
}

@font-face {
  font-family: 'Recoleta-Black';
  src: url('../../public/fonts/Recoleta-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../../public/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../../public/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../../public/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

.gilroy {
  font-family: "Gilroy-Regular", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.gilroy-bold {
  font-family: "Gilroy-Bold", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.roboto-mono {
  font-family: "Roboto Mono", "Helvetica", "Arial", sans-serif !important;
}

.text-medium {
  font-size: 1.1rem !important;
}

.primary {
  color: #5fc69c;
}

.secondary {
  color: #3f4eb3;
}

.light {
  color: rgb(96, 104, 111);
}

.company-name {
  text-transform: capitalize;
}

.skeleton-primary {
  background-color: #5fc69c !important;
}

.paper-primary {
  background-color: #5fc69c !important;
  color: #fff !important;
}

.chip-primary {
  background: linear-gradient(98.72deg, rgb(29, 202, 155) 9.24%, rgb(29, 202, 36) 87.42%) !important;
  color: #fff !important;
}

.card-link .chip-primary {
  transition: background 0.3s ease, padding 0.3s ease;
}

.card-link:hover .chip-primary {
  background: linear-gradient(96deg, rgb(29, 77, 202) 19.45%, rgb(56, 207, 198) 87.31%) !important;
  padding-left: 8px;
  padding-right: 8px;
}

.paper-sdg {
  background-image: none !important;
}

.alert-primary {
  background: linear-gradient(98.72deg, rgb(29, 202, 155) 9.24%, rgb(29, 202, 36) 87.42%) !important;
  color: #fff !important;
}

@media (prefers-reduced-motion: no-preference) {
  .gradient-text-animation {
    animation: move-bg 12s linear infinite;
  }

  @keyframes move-bg {
    to {
      background-position: var(--bg-size)0
    }
  }
}

.gradient-text-animation {
  --bg-size: 400%;
  --color-one: hsl(164, 75%, 45%);
  --color-two: hsl(122, 75%, 45%);
  --color-three: hsl(183, 75%, 45%);
  --color-four: hsl(230, 75%, 45%);
  --color-five: hsl(164, 75%, 45%);
  background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-three), var(--color-four), var(--color-five)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* bounce animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.bounce {
  animation: bounce 2s infinite;
  color: #5fc69c;
}
/* bounce animation */


/* fish animation */
#fish {
  position: fixed;
  margin-top: 120px;
}

svg.fish {
  overflow: visible;
}

@-webkit-keyframes swim {
  0% {
    margin-left: -235px
  }

  90% {
    margin-left: 100%;
  }

  100% {
    margin-left: 100%;
  }
}

@keyframes swim {
  0% {
    margin-left: -235px
  }

  70% {
    margin-left: 100%;
  }

  100% {
    margin-left: 100%;
  }
}

.fish {
  width: 235px;
  height: 104px;
  margin-left: -235px;
  position: absolute;
  animation: swim 20s;
  -webkit-animation: swim 20s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}

svg #fish1,
svg #fish2,
svg #fish3,
svg #fish4,
svg #fish5,
svg #fish6 {
  fill: #49A4DF;
  opacity: 0.2;

  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

svg #fish2 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

svg #fish3 {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

svg #fish4 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

svg #fish5 {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

svg #fish6 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

@-moz-keyframes bounce {

  0%,
  50%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  75% {
    -moz-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

@-webkit-keyframes bounce {

  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  75% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

@keyframes bounce {

  0%,
  50%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  75% {
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

/* fish animation*/